<template>
	<div class="container">
		<div class="login">
			<b-col sm="12">
				<b-row class="row">
					<label>Авторизация</label>
					<b-form-input v-model="email" type="text" placeholder="E-mail"></b-form-input>
				</b-row>
				<b-row class="row">
					<b-form-input v-model="password" type="password" placeholder="Password"></b-form-input>
				</b-row>
				<b-row class="row">
					<b-button variant="primary" size="sm" @click.prevent="onLogin">Войти</b-button>
				</b-row>
			</b-col>
		</div>
		<div class="alert">
			<b-alert variant="danger" class="mb-0" :show="isShowError">{{ error }}</b-alert>
		</div>
	</div>
</template>

<script>
import AuthService from "../services/auth/AuthService"
import LocalStorage from "../utils/localStorage"

export default {
	data() {
		return {
			email: "",
			password: "",
			error: null,
			isShowError: false
		}
	},

	methods: {
		async onLogin() {
			try {
				const { token } = await AuthService.login({ email: this.email, password: this.password })
				LocalStorage.set("token", token)
				this.error = null
				this.$router.replace({ name: "Tasks" })
			} catch (error) {
				this.showError(error)
			}
		},
		showError(error) {
			this.error = error
			this.isShowError = !this.isShowError
			setTimeout(() => {
				this.isShowError = !this.isShowError
				this.error = null
			}, 4000)
		}
	},

	created() {
		const token = LocalStorage.get("token", null)

		if (token) {
			this.$router.replace({ name: "Tasks" })
		}
	}
}
</script>

<style lang="scss">
.container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 98vh;
}

.login {
	margin: 0 auto;
	display: flex;
	width: 320px;
	height: 320px;
	flex-direction: column;
	justify-content: center;
}

.error {
	margin-top: 20px;
	color: #f00;
}

label {
	color: #fff;
}

.row {
	margin-bottom: 10px;
}

.alert {
	min-width: 280px;
	position: fixed;
	bottom: 20px;
	left: 20px;
	z-index: 10;
}
</style>
