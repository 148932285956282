import http from "../../utils/HttpClient"
import { handleErrors } from "../../helpers/errors.helper"

/**
 * Сервис для работы с авторизацией
 */
export default {
    /**
     * Залогинить пользователя
     * @param {Object} data
     */
    async login(data) {
        try {
            return await http.post("login", data)
        } catch (errors) {
            throw handleErrors(errors, "Ошибка входа в аккаунт")
        }
    },

    /**
     * Разлогинить пользователя
     */
    async logout() {
        try {
            return await http.post("logout")
        } catch (errors) {
            throw handleErrors(errors, "Ошибка выхода из аккаунта")
        }
    },

    /**
     * Запросить смену пароля
     * @param {String} email
     */
    async changePassword(email) {
        try {
            return await http.post(`request-reset-password`, { email })
        } catch (errors) {
            throw handleErrors(errors, "Ошибка смены пароля")
        }
    },

    /**
     * Установить новый пароль
     * @param {String} resetToken
     * @param {String} password
     * @param {String} passwordRepeat
     */
    async resetPassword(resetToken, password, passwordRepeat) {
        try {
            return await http.post(`reset-password`, {
                reset_token: resetToken,
                password,
                password_repeat: passwordRepeat
            })
        } catch (errors) {
            throw handleErrors(errors, "Ошибка смены пароля")
        }
    },

    /**
     * Подтвердить регистрацию
     * @param {String} token
     */
    async confirmRegistration(token) {
        try {
            return await http.post(`confirm`, { token })
        } catch (errors) {
            throw handleErrors(errors, "Ошибка подтвеждения почты")
        }
    },

    /**
     * Получить сессии пользователя
     */
    async getSessions() {
        try {
            return await http.get(`session/list`)
        } catch (errors) {
            throw handleErrors(errors, "Ошибка получения сессий")
        }
    },

    /**
     * Удалить сессию пользователя
     * @param {Number|String} id
     */
    async removeSession(id) {
        try {
            id = parseInt(id)

            return await http.delete(`session/${id}`)
        } catch (errors) {
            throw handleErrors(errors, "Ошибка удаления сессии")
        }
    },

    /**
     * Получить историю посещений
     */
    async getHistory() {
        try {
            return await http.get("user/history")
        } catch (errors) {
            throw handleErrors(errors, "Ошибка получения истории посещений")
        }
    }
}